import { Controller } from "@hotwired/stimulus";
import { useTransition } from "stimulus-use";
class src_default extends Controller {
  connect() {
    useTransition(this, {
      element: this.menuTarget
    });
  }
  toggle() {
    this.toggleTransition();
  }
  hide(event) {
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains("hidden")) {
      this.leave();
    }
  }
}
src_default.targets = ["menu"];
export { src_default as default };
